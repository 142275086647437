export class ProjectList {
  static readonly type = '[ProjectList] Post';
  constructor(public param: IProjectListParam) {}
}

export class SetSelectedProject {
  static readonly type = '[SetSelectedProject] Post';
  constructor(public param: IProjectList | null) {}
}

export class WebMilestoneList {
  static readonly type = '[WebMilestoneList] Post';
  constructor(public param: IWebMilestoneParam) {}
}

export class WebMilestoneListClear {
  static readonly type = '[WebMilestoneListClear] Post';
}
